import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import config from '../../../../../config';
import {
  currencyFormatter, formatDecimalToFixedTwoPercent, getExportPrefix,
} from '../../../../../shared/components/table/functions';
import { doFetch } from '../../../../../helpers';
import PpcReportGrid from './PpcReportGrid';
import { isAdRev } from '../../../../../shared/components/domainSupport';

export default function PpcReportMain({ filtersConfig, setLoading, loading, sellerSelect }) {
  const user = useSelector(state => state.authentication.user);

  const columnDefs = [
    {
      field: 'budget_group',
      headerName: 'Budget Group',
    },
    {
      field: 'brand',
      headerName: 'Brand',
    },
    {
      field: 'subcategory',
      headerName: 'Subcategory',
    },
    {
      field: 'segment_one',
      headerName: 'Segment 1',
    },
    {
      field: 'segment_two',
      headerName: 'Segment 2',
    },
    {
      field: 'segment_three',
      headerName: 'Segment 3',
    },
    {
      field: 'segment_four',
      headerName: 'Segment 4',
    },
    {
      field: 'segment_five',
      headerName: 'Segment 5',
    },
    {
      field: 'parent_asin',
      headerName: 'Parent ASIN',
    },
    {
      field: 'title',
      headerName: 'Product Title/Count',
    },
    {
      field: 'number_of_campaigns',
      headerName: '# Of Campaigns',
    },
    {
      field: 'impressions',
      headerName: 'Impressions',
    },
    {
      field: 'clicks',
      headerName: 'Clicks',
    },
    {
      field: 'spend',
      headerName: 'PPC Spend',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'sales',
      headerName: 'PPC Sales',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'orders',
      headerName: 'Orders',
    },
    {
      field: 'units',
      headerName: 'Units',
    },
    // {
    //   field: 'aov',
    //   headerName: 'AOV',
    //   valueFormatter: currencyFormatter,
    //   headerTooltip: 'Average Order Value',
    // },
    {
      field: 'acos',
      headerName: 'PPC ACoS',
      valueFormatter: formatDecimalToFixedTwoPercent,
    },
    {
      field: 'ctr',
      headerName: 'CTR',
      valueFormatter: formatDecimalToFixedTwoPercent,
      headerTooltip: 'Click-through rate',
    },
    {
      field: 'cpc',
      headerName: 'CPC',
      valueFormatter: currencyFormatter,
      headerTooltip: 'Cost per-click',
    },
    {
      field: 'cr',
      headerName: 'CVR',
      valueFormatter: formatDecimalToFixedTwoPercent,
      headerTooltip: 'Conversion rate',
    },
    {
      field: 'roas',
      headerName: 'PPC ROAS',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'spot',
      headerName: 'Spend % of total',
      valueFormatter: formatDecimalToFixedTwoPercent,
      sortable: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: false,
    resizable: true,
    aggFunc: 'none',
    cellStyle: (params) => {
      if (params.value < 0.00) {
        return { color: 'red' };
      }
      return null;
    },
  };

  const pivotOptions = [
    { label: 'Budget Group', value: 'budget_group' },
    { label: 'Brand', value: 'brand' },
    { label: 'Subcategory', value: 'subcategory' },
    { label: 'Parent ASIN', value: 'parent_asin' },
    { label: 'Segment 1', value: 'segment_one' },
    { label: 'Segment 2', value: 'segment_two' },
    { label: 'Segment 3', value: 'segment_three' },
    { label: 'Segment 4', value: 'segment_four' },
    { label: 'Segment 5', value: 'segment_five' },
  ];

  if (sellerSelect.type === 1) {
    pivotOptions.unshift({ label: 'SKU', value: 'sku' })
    columnDefs.unshift(
      {
        field: 'sku',
        headerName: 'SKU',
      },
      {
        field: 'asin',
        headerName: 'ASIN',
      },
    );
  }

  if (sellerSelect.type === 2) {
    pivotOptions.unshift({ label: 'ASIN', value: 'asin' })
    columnDefs.unshift(
      {
        field: 'asin',
        headerName: 'ASIN',
      },
      {
        field: 'sku',
        headerName: 'SKU',
      },
    );
  }

  if (isAdRev()) {
    for (let i = columnDefs.length - 1; i >= 0; i -= 1) {
      if (columnDefs[i].field.startsWith('segment')) {
        columnDefs.splice(i, 1);
      }
    }
    for (let i = pivotOptions.length - 1; i >= 0; i -= 1) {
      if (pivotOptions[i].field.startsWith('segment')) {
        pivotOptions.splice(i, 1);
      }
    }
  }

  function createServer({
    startDate, endDate, pageSize, grouping,
  }) {
    return {
      getData: async (request) => {
        const apiEndPoint = `${config.serverUrl}/amazon/ppc-report`;
        const { startRow, sortModel } = request;
        const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
        const requestOptions = {
          method: 'GET',
          headers: headerWithAuth,
        };

        const url = `${apiEndPoint}?accountId=${sellerSelect.value.toString()}&accountType=${sellerSelect.type}&startDate=${moment(startDate).format('YYYYMMDD')}&endDate=${moment(endDate).format('YYYYMMDD')}&limit=${pageSize}&offset=${startRow}&sort=${encodeURIComponent(JSON.stringify(sortModel))}&grouping=${grouping}&filters=${encodeURIComponent(JSON.stringify(filtersConfig))}`;
        const data = await doFetch(url, requestOptions);
        return data;
      },
    };
  }

  function createDatasource(server) {
    return {
      getRows: async (params) => {
        const {
          request, successCallback: success, failCallback: fail,
        } = params;
        try {
          const response = await server.getData(request);
          success(response.results, response.rowCount);
        } catch (error) {
          console.error(error);
          fail();
        }
      },
    };
  }

  const [reportStartDate, setReportStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
  const [reportEndDate, setReportEndDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  const [pivotOption, setPivotOption] = useState(pivotOptions[0]);
  const [dynamicColumnDefs, setDynamicColumnDefs] = useState(columnDefs);
  const paginationPageSize = 100000;
  const server = createServer({
    startDate: reportStartDate, endDate: reportEndDate, pageSize: paginationPageSize, grouping: pivotOption.value,
  });
  const dataSource = createDatasource(server);

  function onDateChange(startDate, endDate) {
    setReportStartDate(moment(startDate).format('YYYY-MM-DD'));
    setReportEndDate(moment(endDate).format('YYYY-MM-DD'));
  }

  useEffect(() => {
    if (pivotOption.value === 'sku' || pivotOption.value === 'asin') {
      setDynamicColumnDefs(columnDefs);
    }
    if (pivotOption.value !== 'sku' && pivotOption.value !== 'asin') {
      const hideColumns = ['sku', 'asin', 'budget_group', 'brand', 'subcategory', 'parent_asin', 'segment_one', 'segment_two', 'segment_three', 'segment_four', 'segment_five'];
      const pivotColumnDefs = columnDefs.filter((def => {
        if (!hideColumns.includes(def.field) || def.field === pivotOption.value) {
          return true;
        }
        return false;
      }));
      setDynamicColumnDefs(pivotColumnDefs);
    }
  }, [pivotOption]);

  useEffect(() => {
    if (sellerSelect.type === 1 && pivotOption.value === 'asin') {
      setPivotOption(pivotOptions[0]);
    }
    if (sellerSelect.type === 2 && pivotOption.value === 'sku') {
      setPivotOption(pivotOptions[0]);
    }
  }, [sellerSelect]);

  const exportPrefix = getExportPrefix(`amz-ppc-report-${pivotOption.value}`, sellerSelect, true);

  return (
    <PpcReportGrid
      gridInfo={{
        columnDefs: dynamicColumnDefs,
        defaultColDef,
        dataSource,
        paginationPageSize,
      }}
      onDateChange={onDateChange}
      pivot={{
        options: pivotOptions,
        option: pivotOption,
        set: setPivotOption,
      }}
      reportStartDate={reportStartDate}
      reportEndDate={reportEndDate}
      filtersConfig={filtersConfig}
      exportPrefix={exportPrefix}
      loading={loading}
      setLoading={setLoading}
      sellerSelect={sellerSelect}
    />
  );
}
